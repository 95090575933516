import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import useForm from '../utils/useForm';
import useQuote from '../utils/useQuote';
import Layout from '../components/layout';
import Seo from '../components/seo';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ContactForm() {
  const { values, updateValue } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    messageText: '',
    mapleSyrup: '',
  });
  const { error, loading, message, requiredFields, submitForm } = useQuote({
    values,
  });

  if (message) {
    return <p>{message}</p>;
  }
  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="Send us a message using the form below. We will get back to you shortly."
      />
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <StaticImage
              src="../images/gallery/vert/kitchen-lights.jpg"
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Geo Technical Field"
            />
          </div>
        </div>
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
          <div className="lg:pr-8">
            <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
              <h2 className="page-title uppercase">Let's work together</h2>
              <p className="mt-4 text-xl text-gray-500 sm:mt-3">
                Send us a message using the form below.
              </p>

              <form
                action="#"
                method="POST"
                onSubmit={submitForm}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                {/* First Name */}
                <div>
                  <label
                    htmlFor="first-name"
                    className="block  font-medium text-gray-700"
                  >
                    First name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={updateValue}
                      autoComplete="given-name"
                      className={classNames(
                        requiredFields.firstName
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                  </div>
                  {requiredFields.firstName && (
                    <p className="text-red-500">{requiredFields.firstName}</p>
                  )}
                </div>

                {/* Last Name */}
                <div>
                  <label
                    htmlFor="last-name"
                    className="block  font-medium text-gray-700"
                  >
                    Last name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      onChange={updateValue}
                      autoComplete="family-name"
                      className={classNames(
                        requiredFields.lastName
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                  </div>
                  {requiredFields.lastName && (
                    <p className="text-red-500">{requiredFields.lastName}</p>
                  )}
                </div>

                {/* Email */}
                <div className="sm:col-span-1">
                  <label
                    htmlFor="email"
                    className="block  font-medium text-gray-700"
                  >
                    Email <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={updateValue}
                      autoComplete="email"
                      className={classNames(
                        requiredFields.email
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                    <input
                      id="mapleSyrup"
                      name="mapleSyrup"
                      type="text"
                      value={values.mapleSyrup}
                      onChange={updateValue}
                      className="hidden"
                    />
                  </div>
                  {requiredFields.email && (
                    <p className="text-red-500">{requiredFields.email}</p>
                  )}
                </div>

                {/* Phone */}
                <div className="sm:col-span-1">
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block  font-medium text-gray-700"
                    >
                      Phone <span className="text-red-500">*</span>
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={values.phone}
                      onChange={updateValue}
                      autoComplete="tel"
                      aria-describedby="phone-description"
                      className={classNames(
                        requiredFields.phone
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                  </div>
                  {requiredFields.phone && (
                    <p className="text-red-500">{requiredFields.phone}</p>
                  )}
                </div>

                {/* Address */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="address"
                    className="block  font-medium text-gray-700"
                  >
                    Project Address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={values.address}
                      onChange={updateValue}
                      autoComplete="organization"
                      className={classNames(
                        requiredFields.address
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                  </div>
                  {requiredFields.address && (
                    <p className="text-red-500">{requiredFields.address}</p>
                  )}
                </div>

                {/* Message Text */}
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="how-can-we-help"
                      className="block  font-medium text-gray-700"
                    >
                      How can we help you?{' '}
                      <span className="text-red-500">*</span>
                    </label>
                    <span
                      id="how-can-we-help-description"
                      className=" text-gray-500"
                    >
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="messageText"
                      name="messageText"
                      value={values.messageText}
                      onChange={updateValue}
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      className={classNames(
                        requiredFields.messageText
                          ? 'form-field-error'
                          : 'focus:border-accent focus:ring-accent',
                        'form-field'
                      )}
                    />
                  </div>
                  {requiredFields.messageText && (
                    <p className="text-red-500">{requiredFields.messageText}</p>
                  )}
                </div>

                <div className="text-right sm:col-span-2">
                  <button
                    type="submit"
                    disabled={loading}
                    className="inline-flex justify-center border border-transparent bg-primary px-4 py-2 font-medium  text-white shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                  >
                    {loading ? 'Sending' : 'Contact Us'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactForm;
