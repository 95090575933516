import { useState } from 'react';

export default function useForm({ values }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [requiredFields, setRequiredFields] = useState({});

  const checkRequiredFields = () => {
    const requiredFieldsList = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'address',
      'messageText',
    ];
    const missingFields = requiredFieldsList.filter((field) => !values[field]);
    const requiredFieldsObj = {};
    missingFields.forEach((field) => {
      requiredFieldsObj[field] = 'Required field';
    });
    return requiredFieldsObj;
  };

  // function run when someone submits quote
  async function submitForm(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address: values.address,
      messageText: values.messageText,
      mapleSyrup: values.mapleSyrup,
    };

    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/contactForm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    const text = JSON.parse(await res.text());

    // check if everthing worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false); // turn off loading
      setError(text.message);
      setRequiredFields(checkRequiredFields());
    } else {
      setLoading(false);
      // setMessage('Success quote request');
      document.location = '/thank-you';
    }
  }

  return { error, loading, message, requiredFields, submitForm };
}
